import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, confirmed, length, email, min_value, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

Vue.component('v-validation-observer',ValidationObserver)
Vue.component('v-validation-provider',ValidationProvider)

extend("required", {
  ...required,
  message: "{_field_} is required"
});

extend("email", {
  ...email,
  message: "{_field_} must be valid"
});

extend("confirmed", {
  ...confirmed,
  message: "Password does not match"
});

extend("length", {
  ...length,
  message: "{_field_} does not acquired to the said length"
});

extend('minValue', {
  ...min_value,
  message: "{_field_} does not acquired to minimum value {min}"
})

extend('min', {
  ...min,
  message: "{_field_}'s minimum length ({length})"
})