export const ROLE = {
  ADMIN: 1,
  USER: 2,
  GUEST: 3
}

export const API_ROUTES = {
	//AUTHENTICATION
  POST_LOGIN: () => '/api/auth/login',
  GET_VERIFY_AUTH: (userId) => `/api/auth/verify/${userId}`,
  GET_GENERATE_CODE: (userId) => `/api/auth/code/${userId}`,

	POST_SEND_CODE: () => `/api/auth/password/send-code`,
	POST_VERIFY_CODE: (userId) => `/api/auth/password/verify-code/${userId}`,
	POST_RESET_PASSWORD: (userId) => `/api/auth/password/reset/${userId}`,

	//USERS
  GET_USERS: (limit, page, search, groupId, emailVerified, active) => `/api/users?limit=${limit}&page=${page}&search=${search}&group_id=${groupId}&email_verified=${emailVerified}&active=${active}`,
	ADD_USER: () => `/api/users/`,
	UPDATE_USER: (id) => `/api/users/${id}`,
	DELETE_USER: (id) => `/api/users/${id}`,
	DELETE_FORCE_USER: (id) => `/api/users/${id}?force_delete=1`,

	//SHARED USERS
	GET_USER_EMAILS: (userId, queryAll) => `/api/shared/user-emails?user_id=${userId}&query_all=${queryAll}`,
	GET_SHARED_USERS_CODE: () => `/api/shared/user-codes`,
	POST_SHARE_USERTO: () => `/api/shared/user`,
	GET_SHARED_USERS: (userId) => `/api/shared/user/${userId}`,
	DELETE_SHARED_USER: (sharedUserId) => `/api/shared/user/${sharedUserId}`,
	POST_BULK_SHARING: () => `/api/shared/bulk-sharing`,

	//USERS LOGS
	GET_USER_LOGS: (limit, page, search) => `/api/logs?limit=${limit}&page=${page}&search=${search}`,
}