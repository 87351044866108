<template>
  <div class="d-flex vh-100 justify-content-center align-items-center">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 w-100">
          <b-alert v-if="form === 1" class="text-center" show variant="info" :style="{ width: '450px' }">
            <small>Password Reset may not be available to all users.</small>
          </b-alert>

          <b-card class="shadow-sm px-2" :style="{ width: '450px' }">
            <!-- SENDING CODE FOR PASSWORD RECOVERY -->
            <div v-if="form === 1" class="card-body">
              <div class="header">
                <h3>Password Recovery</h3>
                <small>Enter your email here, we will send code to your email.</small>
              </div>
              <div class="form mt-3">
                <div class="mb-3">
                  <b-input
                  type="email"
                  id="email"
                  v-model="email"
                  placeholder="Enter Email"
                  ></b-input>
                </div>
                <div class="mb-3 d-flex justify-content-between align-items-center">
                  <router-link to="/login" class="btn btn-secondary">Cancel</router-link>
                  <b-button variant="primary" :disabled="!email || loading" @click="sendCodeHandler">
                    {{ loading ? 'Sending' : 'Send'}} 
                  </b-button>
                </div>
              </div>
            </div>

            <!-- ENTER VERIFICATION CODE FOR PASSWORD RECOVERY -->
            <div v-if="form === 2" class="card-body">
              <div class="header">
                <h3>Password Recovery</h3>
                <small>Enter your verification code.</small>
              </div>
              <div class="form mt-3">
                <div class="mb-3">
                  <b-input
                  v-model="code"
                  id="code"
                  placeholder="Enter Verification Code"
                  />
                </div>
                <div class="mb-3 d-flex justify-content-between align-items-center">
                  <div>
                    <router-link to="/login" class="btn btn-secondary">Cancel</router-link>
                    <b-button class="me-2" variant="link" :disabled="!email || loading ? true : false" @click="reSendCodeHandler">
                      Resend Code
                    </b-button>

                    <b-spinner v-if="resendLoading" label="Loading..." :style="{ width: '1rem', height: '1rem' }"></b-spinner>
                  </div>
                  
                  <b-button class="px-4" variant="primary" :disabled="!code || loading ? true : false" @click="verifyCodeHandler">
                    {{ loading ? 'Verifying' : 'Verify' }} 
                  </b-button>
                </div>
              </div>
            </div>

            <!-- RESET PASSWORD -->
            <div v-if="form === 3" class="card-body">
              <div class="header">
                <h3>NEW PASSWORD</h3>
              </div>
              <div class="form mt-3">
                <div class="mb-3">
                  <b-input
                  type="password"
                  id="password"
                  placeholder="Password"
                  v-model="password"
                  />
                </div>
                <div class="mb-3">
                  <b-input
                  type="password"
                  id="confirm-password"
                  placeholder="Confirm Password"
                  v-model="confirmPassword"
                  />
                </div>
                <div class="mb-3 d-flex justify-content-between align-items-center">
                  <router-link to="/login" class="btn btn-secondary">Cancel</router-link> 
                  <b-button variant="primary" class="px-4" :disabled="(!password && !confirmPassword) || loading ? true : false" @click="resetPasswordHandler">
                    Submit
                  </b-button>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
</template>

<script>
import Api from '../services/ApiService'
import { API_ROUTES } from '../constants'
export default {
  data: () => ({
    userData: {
      userId: "",
      email: ""
    },

    email: "",
    code: "",
    password: "",
    confirmPassword: "",
    form: 1,
    loading: false,
    resendLoading: false
  }),
  methods: {
    async sendCodeHandler() {
      this.loading = true
      try {
        let response = await Api.post(API_ROUTES.POST_SEND_CODE(), JSON.stringify({ email: this.email }))
        let { user, message } = response.data
        this.userData = { email: user.email, userId: user.id }
        this.$bvToast.toast(message, {
          title: `Success`,
          variant: 'success',
          solid: true
        })
        this.form = 2
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      } finally {
        this.loading = false
      }
    },
    async reSendCodeHandler(){
      this.resendLoading = true
      try {
        let response = await Api.post(API_ROUTES.POST_SEND_CODE(), JSON.stringify({ email: this.email }))
        let { user } = response.data
        this.userData = { email: user.email, userId: user.id }
        this.$bvToast.toast(`Code resend to your email (${user.email})`, {
          title: `Success`,
          variant: 'success',
          solid: true
        })
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      } finally {
        this.resendLoading = false
      }
    },

    async verifyCodeHandler() {
      this.loading = true
      try {
        let response = await Api.post(API_ROUTES.POST_VERIFY_CODE(this.userData.userId), JSON.stringify({ code: this.code }))
        let { isMatched, message } = response.data
        if(isMatched){
          this.$bvToast.toast(message, {
            title: `Success`,
            variant: 'success',
            solid: true
          })
          this.form = 3
        }
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      } finally {
        this.loading = false
      }
    },

    async resetPasswordHandler(){
      this.loading = true
      try {
        await Api.post(API_ROUTES.POST_RESET_PASSWORD(this.userData.userId), JSON.stringify({
          password: this.password,
          confirmPassword: this.confirmPassword
        }))
        this.loading = false
        this.$bvToast.toast('Password Reset!', {
          title: `Success`,
          variant: 'success',
          solid: true
        })
        this.$router.push('/login')
      } catch (error) {
        this.loading = false
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      }
    }
  },
}
</script>
