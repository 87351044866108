<template>
  <b-container fluid class="my-3">
    <div class="d-flex justify-content-between mb-2">
      <h5><strong>Manage Users</strong></h5>
      <div>
        <b-button class="mr-1" variant="primary" size="sm" @click="onBulkSharingModal">Bulk Sharing</b-button>
        <b-button variant="primary" size="sm" @click="onUserFormModal(1, null)">Add User</b-button>
      </div>
    </div>

    <b-card>
      <b-card-text>
        <b-row>
          <b-col sm md="5" lg="6" class="left-control">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchFilter"
                  placeholder="Search"
                  @input="loadUsers"
                  type="search"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm md="7" lg="6" class="right-control">
            <b-form-group>
              <b-input-group>
                <b-form-select
                  v-model="userRoleFilter"
                  :options="userRoleFilterOptions"
                  @input="onFilterChanged"
                ></b-form-select>
                <b-form-select
                  v-model="userStatusFilter"
                  :options="userStatusFilterOptions"
                  @input="onFilterChanged"
                ></b-form-select>
                <b-form-select
                  v-model="emailStatusFilter"
                  :options="emailStatusFilterOptions"
                  @input="onFilterChanged"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <div>
          <b-table
          small
          id="manage-users-table"
          class="border-bottom"
          show-empty
          :empty-text="loading ? 'Loading...' : 'There are no users to show'"
          :busy="loading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          stacked="sm"
          hover
          >
            <template #cell(user_group_id)="data">
              {{ 
                data.item.user_group_id === ROLE.ADMIN ? 'Admin' : 
                data.item.user_group_id === ROLE.USER ? 'User' :
                data.item.user_group_id === ROLE.GUEST ? 'Guest' : ''
              }}
            </template>

            <template #cell(email_verified)="data">
              <span v-if="data.item.email_verified === 1" class="badge bg-success text-white">Verified</span>
              <span v-else class="badge bg-secondary text-white">Not Verified</span>
            </template>

            <template #cell(active)="data">
              <span v-if="data.item.active === 0" class="badge bg-secondary text-white">Inactive</span>
              <span v-if="data.item.active === 1" class="badge bg-success text-white">Active</span>
              <span v-if="data.item.active === 2" class="badge bg-warning text-white">Initially Inactive</span>
            </template>

            <template #cell(loggedin_at)="data">
              <small v-if="data.item.loggedin_at">{{ data.item.loggedin_at }}</small>
              <span v-else class="badge bg-secondary text-white">null</span>
            </template>

            <template #cell(actions)="data">
              <b-dropdown id="users-action-dropdown" text="Manage" variant="primary" right size="sm">
                <b-dropdown-item @click="onUserFormModal(2, data.item)">
                  <b-icon class="mr-1" icon="pencil-square"></b-icon> Update User
                </b-dropdown-item>
                <b-dropdown-item @click="onShareAccountsModal(data.item)">
                  <b-icon class="mr-1" icon="share"></b-icon> Share Other Accounts
                </b-dropdown-item>
                <b-dropdown-item @click="onViewSharedAccountsModal(data.item)">
                  <b-icon class="mr-1" icon="person-lines-fill"></b-icon> View Shared Accounts
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="onDeleteUserModal(data.item)">
                  <b-icon class="mr-1 text-danger" icon="trash"></b-icon> Delete User
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <b-pagination
            v-if="items.length > 0"
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            aria-controls="manage-users-table"
            last-number
            first-number
            align="center"
            hide-goto-end-buttons
            @change="onPaginate"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="userFormModal" :title="userFormOption.title" hide-footer size="lg">
      <v-validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onUserFormSubmit)">
          <b-row>
            <b-col sm="12" md="4" lg="4">
                <v-validation-provider name="User Role" rules="required" v-slot="{ errors }">
                  <b-form-group class="form-label" label-size="sm" label-for="userrole" label="User Role">
                    <b-form-select class="mt-n2" size="sm" id="userrole" v-model="userFormData.user_group_id" :options="userRoleFilterOptions" :state="errors[0]?false:null"></b-form-select>
                    <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                  </b-form-group>
                </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Username" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="username" label="Username">
                  <b-form-input class="mt-n2" size="sm" id="username" v-model.trim="userFormData.username" :state="errors[0]?false:null"  placeholder="Enter Username" autocomplete="username"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Email" rules="required|email" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="email" label="Email">
                  <b-form-input class="mt-n2" size="sm" id="email" v-model.trim="userFormData.email" :state="errors[0]?false:null"  placeholder="Enter Email" autocomplete="email"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Password" :rules="userFormOption.type === 1 ? 'required|min:8' : ''" v-slot="{ errors }" vid="confirmPassword">
                <b-form-group class="form-label" label-for="password" label-size="sm" label="Password">
                  <b-form-input class="mt-n2" size="sm" id="password" v-model.trim="userFormData.password" :state="errors[0]?false:null"  placeholder="Enter Password" type="password" autocomplete="new-password"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Confirm Password" :rules="userFormOption.type === 1 ? 'required|min:8|confirmed:confirmPassword' : ''" v-slot="{ errors }">
                <b-form-group class="form-label" label-for="confirmPassword" label-size="sm" label="Confirm Password">
                  <b-form-input class="mt-n2" size="sm" id="confirmPassword" v-model.trim="userFormData.confirm_password" :state="errors[0]?false:null"  placeholder="Enter Confirm Password" type="password" autocomplete="new-password"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}} </b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="User Status" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="userstatus" label="User Role">
                  <b-form-select class="mt-n2" size="sm" id="userstatus" v-model="userFormData.active" :options="userStatusFilterOptions" :state="errors[0]?false:null"></b-form-select>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="First Name" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="firstname" label="First Name">
                  <b-form-input class="mt-n2" size="sm" id="firstname" v-model.trim="userFormData.first_name" :state="errors[0]?false:null"  placeholder="Enter First Name"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Last Name" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="lastname" label="Last Name">
                  <b-form-input class="mt-n2" size="sm" id="lastname" v-model.trim="userFormData.last_name" :state="errors[0]?false:null"  placeholder="Enter Last Name"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Email Status" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="emailstatus" label="Email Status">
                  <b-form-select class="mt-n2" size="sm" id="emailstatus" v-model="userFormData.email_verified" :options="emailStatusFilterOptions" :state="errors[0]?false:null"></b-form-select>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="4" lg="4">
              <v-validation-provider name="Enable Login" rules="required" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="enablelogin" label="Enable Login?">
                  <b-form-select class="mt-n2" size="sm" id="enablelogin" v-model="userFormData.is_login_enabled" :options="[ { value: '', text: 'Select' }, {value: '0', text: 'No'}, {value: '1', text: 'Yes'}, ]" :state="errors[0]?false:null"></b-form-select>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
            <b-col sm="12" md="8" lg="8">
              <v-validation-provider name="Amazon 2FA Secret Key" rules="" v-slot="{ errors }">
                <b-form-group class="form-label" label-size="sm" label-for="amazon2fa" label="Amazon 2FA Secret Key">
                  <b-form-input class="mt-n2" size="sm" id="amazon2fa" v-model.trim="userFormData.amazon_2fa" :state="errors[0]?false:null"  placeholder="Enter Amazon 2FA Secret Key"></b-form-input>
                  <b-form-invalid-feedback :state="errors[0]?false:null">{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </v-validation-provider>
            </b-col>
          </b-row>

          <b-form-group class="text-right mb-n1">
              <b-button size="sm" variant="secondary" @click="userFormModal = false" class="mr-2" :disabled="userFormLoading">Cancel</b-button>
              <b-button size="sm" variant="primary" type="submit" :disabled="invalid || userFormLoading">
                Submit
              </b-button>
          </b-form-group>
        </b-form>
      </v-validation-observer>
    </b-modal>

    <b-modal v-model="shareAccountsModal" title="Share Accounts">
      <b-card-text>
        <label class="form-label">Select Account(s) to be shared:</label>
        <multiselect
        placeholder="Search Email"
        v-model="shareAccountsSelectedAccounts"
        :options="shareAccountsEmails"
        label="email" track-by="email"
        :multiple="true"
        :clear-on-select="false"
        :loading="shareAccountsOptionLoading"
        >
          <template #noResult>
            No emails found!
          </template>
        </multiselect>
      </b-card-text>

      <template #modal-footer>
        <div class="float-right">
          <b-button variant="secondary" class="mr-1" @click="shareAccountsModal = false" :disabled="shareAccountsLoading">Cancel</b-button>
          <b-button variant="primary" @click="onShareAccountSubmit" :disabled="shareAccountsLoading">
            {{ loading ? 'Sharing' : 'Share' }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="viewSharedAccountsModal" title="Shared Accounts" hide-footer size="lg">
      <b-table
      class="border-bottom"
      show-empty
      :empty-text="viewSharedAccountsTableLoading ? 'Loading...' : 'There are no users to show'"
      :busy="viewSharedAccountsTableLoading"
      small
      hover
      :fields="viewSharedAccountsTableFields"
      :items="viewSharedAccountsTableItems"
      stacked="sm"
      >
        <template #cell(action)="data">
          <b-button variant="danger" size="sm" @click="onDeleteSharedAccount(data.item)">Remove</b-button>
        </template>
      </b-table>
    </b-modal>

    <b-modal v-model="deleteUserModal" title="Delete User">
      <b-card-text>
        This user will be fully removed into the database. Are you sure you want to delete user {{ deleteUserData && (`(${deleteUserData.email})`) }} ?
      </b-card-text>

      <template #modal-footer>
        <div class="float-right">
          <b-button class="mr-1" variant="secondary" @click="deleteUserModal = false">Cancel</b-button>
          <b-button variant="danger" @click="onDeleteUser" :disabled="deleteUserLoading">
            Delete
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="bulkSharingModal" title="Bulk Sharing">
      <b-card-text>
        <label class="form-label">Select Accounts Holder:</label>
        <multiselect
        placeholder="Search Email"
        v-model="bulkSharingSelectedHolderEmails"
        :options="bulkSharingHolderEmails"
        label="email" track-by="email"
        :multiple="true"
        :clear-on-select="false"
        :loading="bulkSharingOptionLoading"
        @select="onHolderEmailOptionSelect"
        @remove="onHolderEmailOptionRemove"
        >
          <template #noResult>
            No emails found!
          </template>
        </multiselect>

        <hr />

        <label class="form-label">Select Account(s) to be shared:</label>
        <multiselect
        placeholder="Search Email"
        v-model="bulkSharingSelectedSharedEmails"
        :options="bulkSharingSharedEmails"
        label="email" track-by="email"
        :multiple="true"
        :clear-on-select="false"
        :loading="bulkSharingOptionLoading"
        @select="onSharedEmailOptionSelect"
        @remove="onSharedEmailOptionRemove"
        >
          <template #noResult>
            No emails found!
          </template>
        </multiselect>
      </b-card-text>

      <template #modal-footer>
        <div class="float-right">
          <b-button variant="secondary" class="mr-1" @click="bulkSharingModal = false" :disabled="bulkSharingLoading">
            Cancel
          </b-button>
          <b-button variant="primary" @click="onBulkSharingSubmit" :disabled="bulkSharingLoading">
            Submit
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from 'vue-multiselect'
import Api from '../services/ApiService'
import { formatDate } from '../services/DateService'
import { ROLE, API_ROUTES } from '../constants'
export default {
  components: {
    'multiselect': Multiselect
  },
  data: () => ({
    ROLE,
    loading: false,
    items: [],
    fields: [
      {
        key: 'user_group_id',
        label: 'Role',
        class: 'align-middle'
      },
      {
        key: 'username',
        label: 'Username',
        class: 'align-middle'
      },
      {
        key: 'email',
        label: 'Email',
        class: 'align-middle'
      },
      {
        key: 'first_name',
        label: 'First Name',
        class: 'align-middle'
      },
      {
        key: 'last_name',
        label: 'Last name',
        class: 'align-middle'
      },
      {
        key: 'email_verified',
        label: 'Email Status',
        class: 'align-middle text-center'
      },
      {
        key: 'active',
        label: 'User Status',
        class: 'align-middle text-center'
      },
      {
        key: 'loggedin_at',
        label: 'Logged-in Last',
        class: 'align-middle'
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'align-middle text-center'
      },
    ],

    searchFilter: '',
    userRoleFilter: '',
    userRoleFilterOptions: [
      { text: 'Select User Role', value: '' },
      { text: 'Admin', value: 1 },
      { text: 'User', value: 2 },
      { text: 'Guest', value: 3 }
    ],
    userStatusFilter: '',
    userStatusFilterOptions: [
      { text: 'Select User Status', value: '' },
      { text: 'Inactive', value: 0 },
      { text: 'Active', value: 1 },
      { text: 'Initially Active', value: 2 }
    ],
    emailStatusFilter: '',
    emailStatusFilterOptions: [
      { text: 'Select Email Status', value: '' },
      { text: 'Not Verified', value: 0 },
      { text: 'Verified', value: 1 }
    ],

    pagination: {
      currentPage: 1,
      perPage: 20,
      totalRows: 1
    },

    //add user
    userFormDefaultData: {
      id: '',
      user_group_id: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      email_verified: '',
      is_login_enabled: '',
      active: '',
      amazon_2fa: ''
    },
    userFormModal: false,
    userFormOption: {
      type: 1,
      title: 'Add'
    },
    userFormData: {
      user_group_id: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      email_verified: '',
      is_login_enabled: '',
      active: '',
      amazon_2fa: ''
    },
    userFormLoading: null,

    //share account user
    shareAccountsModal: false,
    shareAccountsData: null,
    shareAccountsOptionLoading: false,
    shareAccountsEmails: [],
    shareAccountsLoading: false,
    shareAccountsSelectedAccounts: [],

    //view shared user accounts
    viewSharedAccountsModal: false,
    viewSharedAccountsData: null,
    viewSharedAccountsTableLoading: null,
    viewSharedAccountsTableItems: [],
    viewSharedAccountsTableFields: [
      {
        key: 'shared_user',
        label: 'Shared User',
        class: 'align-middle'
      },
      {
        key: 'shared_by',
        label: 'Shared By',
        class: 'align-middle'
      },
      {
        key: 'shared_at',
        label: 'Shared Date',
        class: 'align-middle'
      },
      {
        key: 'action',
        label: 'Action',
        class: 'align-middle text-center'
      },
    ],

    //delete user
    deleteUserModal: false,
    deleteUserData: null,
    deleteUserLoading: false,

    //bulk sharing
    bulkSharingModal: false,
    bulkSharingLoading: false,

    bulkSharingOptionLoading: false,
    bulkSharingHolderEmails: [],
    bulkSharingSelectedHolderEmails: [],
    bulkSharingSharedEmails: [],
    bulkSharingSelectedSharedEmails: [],

  }),
  created() {
    this.loadUsers()
  },
  methods: {
    async loadUsers(){
      this.loading = true
      let { data } = await Api.get(API_ROUTES.GET_USERS(this.pagination.perPage, this.pagination.currentPage, this.searchFilter, this.userRoleFilter, this.emailStatusFilter, this.userStatusFilter))
      let items = data.items.map(item => {
        return {
          ...item,
          loggedin_at: item.loggedin_at ? formatDate(item.loggedin_at) : null
        }
      })
      this.items = items
      this.pagination = {
        ...this.pagination,
        totalRows: data.totalItems
      }
      this.loading = false
    },
    onFilterChanged() {
      this.pagination.currentPage = 1
      this.loadUsers()
    },
    onPaginate(page){
      this.pagination = { ...this.pagination, currentPage: page }
      this.loadUsers()
    },
    onResetTableAndFilters(){
      this.searchFilter = ''
      this.userRoleFilter = ''
      this.userStatusFilter = ''
      this.emailStatusFilter = ''
      this.pagination.currentPage = 1
      this.loadUsers()
    },
    toast(title, message, variant){
      this.$bvToast.toast(message, { title, variant, solid: true })
    },
    onUserFormModal(type, data){
      if(type === 1){
        this.userFormOption = { type: 1, title: 'Add User' }
        this.userFormData = Object.assign({}, this.userFormDefaultData)
      }else{
        this.userFormOption = { type: 2, title: 'Update User' }
        this.userFormData = Object.assign({}, data)
      }
      this.userFormModal = true
    },
    async onUserFormSubmit(){
      this.userFormLoading = true
      try {
        if(this.userFormOption.type === 1){
          let response = await Api.post(API_ROUTES.ADD_USER(), JSON.stringify(this.userFormData)) 
          this.toast('Success', response.data.message, 'success')
        }
        if(this.userFormOption.type === 2){
          let response = await Api.put(API_ROUTES.UPDATE_USER(this.userFormData.id), JSON.stringify(this.userFormData)) 
          this.toast('Success', response.data.message, 'success')
        }
        this.userFormLoading = false
        this.userFormModal = false
        this.onResetTableAndFilters()
      } catch (error) {
        this.toast('Error', error.data.message, 'danger')
        this.userFormLoading = false
      }
    },

    async loadSharedEmails(){
      if(this.shareAccountsData){
        this.shareAccountsOptionLoading = true
        let response = await Api.get(API_ROUTES.GET_USER_EMAILS(this.shareAccountsData.id, ''))
        this.shareAccountsEmails  = response.data
        this.shareAccountsOptionLoading = false
      } 
    },
    onShareAccountsModal(data){
      this.shareAccountsData = data
      this.loadSharedEmails()
      this.shareAccountsModal = true
    },
    async onShareAccountSubmit(){
      this.shareAccountsLoading = true
      try {
        if(!this.shareAccountsSelectedAccounts.length){
          this.toast('Error', "Please select atleat 1 user account to be shared!", 'danger')
          this.shareAccountsLoading = false
          return;
        }

        let response = await Api.post(API_ROUTES.POST_SHARE_USERTO(), JSON.stringify({
          user_id: this.shareAccountsData.id,
          user_email: this.shareAccountsData.email,
          users: this.shareAccountsSelectedAccounts
        }))
        
        this.shareAccountsSelectedAccounts = []
        this.toast('Success', response.data.message, 'success')
        this.shareAccountsLoading = false
        this.shareAccountsModal = false
      } catch (error) {
        this.toast('Error', error.data.message, 'danger')
        this.shareAccountsLoading = false
      }
    },
    
    async loadSharedAccount(){
      if(this.viewSharedAccountsData){
        this.viewSharedAccountsTableLoading = true
        let response = await Api.get(API_ROUTES.GET_SHARED_USERS(this.viewSharedAccountsData.id))
        let userResponse = response.data.map(item => {
          return {
            ...item,
            shared_at: formatDate(item.shared_at)
          }
        })
        this.viewSharedAccountsTableItems = userResponse
        this.viewSharedAccountsTableLoading = false
      } 
    },
    onDeleteSharedAccount(e) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this shared account (${e.shared_user})?`, {
        title: 'Delete Shared Account',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false
      })
      .then(async value => {
        if(value){
          let response = await Api.delete(API_ROUTES.DELETE_SHARED_USER(e.id))
          this.toast('Success',response.data.message, 'success')
          this.viewSharedAccountsTableItems = this.viewSharedAccountsTableItems.filter(item => item.id !== e.id)
        }
      })
    },
    onViewSharedAccountsModal(data){
      this.viewSharedAccountsData = data
      this.viewSharedAccountsModal = true
      this.loadSharedAccount()
    },

    onDeleteUserModal(item){
      this.deleteUserModal = true
      this.deleteUserData = item
    },
    async onDeleteUser(){
      this.deleteUserLoading = true
      try {
        await Api.delete(API_ROUTES.DELETE_USER(this.deleteUserData.id))
        this.deleteUserLoading = false
        this.toast('Success', `User (${this.deleteUserData.email}) deleted!`, 'success')
        this.deleteUserModal = false
        this.onResetTableAndFilters()
      } catch (error) {
        this.deleteUserLoading = false
        this.toast('Error', error.data.message, 'danger')
      }
    },

    onBulkSharingModal(){
      this.loadBulkSharingEmails()
      this.bulkSharingModal = true
    },
    async loadBulkSharingEmails(){
      this.bulkSharingOptionLoading = true
      let response = await Api.get(API_ROUTES.GET_USER_EMAILS(null, '1'))
      this.bulkSharingHolderEmails = response.data
      this.bulkSharingSharedEmails = response.data
      this.bulkSharingOptionLoading = false
    },
    onHolderEmailOptionSelect(option){
      this.bulkSharingSharedEmails = this.bulkSharingSharedEmails.filter(item => item.id !== option.id)
    },
    onHolderEmailOptionRemove(option){
      this.bulkSharingSharedEmails.push(option)
      this.bulkSharingSharedEmails = this.bulkSharingSharedEmails.sort((a, b) => a.email.localeCompare(b.email))
    },
    onSharedEmailOptionSelect(option){
      this.bulkSharingHolderEmails = this.bulkSharingHolderEmails.filter(item => item.id !== option.id)
    },
    onSharedEmailOptionRemove(option){
      this.bulkSharingHolderEmails.push(option)
      this.bulkSharingHolderEmails = this.bulkSharingHolderEmails.sort((a, b) => a.email.localeCompare(b.email))
    },
    async onBulkSharingSubmit(){
      this.bulkSharingLoading = true
      try {
        let response = await Api.post(API_ROUTES.POST_BULK_SHARING(), JSON.stringify({
          holder_users: this.bulkSharingSelectedHolderEmails,
          shared_users: this.bulkSharingSelectedSharedEmails
        }))

        this.bulkSharingSelectedHolderEmails = []
        this.bulkSharingSelectedSharedEmails = []

        this.toast('Success', response.data.message, 'success')
        this.bulkSharingLoading = false
        this.bulkSharingModal = false
      } catch (error) {
        this.toast('Error', error.data.message, 'danger')
        this.bulkSharingLoading = false
      }
    }
  }
}
</script>