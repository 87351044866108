<template>
<div id="login" class="d-flex justify-content-center align-items-center vh-100">
    <div class="d-flex flex-column" style="width: 22rem;">
        <b-alert v-if="alert" class="text-center" show :variant="alert.type">{{alert.message}}</b-alert>
        <b-card class="shadow-sm px-2 py-2" ref="container">
            <v-validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                    <h2 class="my-3 font-weight-light">Login</h2>
                    <v-validation-provider name="Email" rules="required" v-slot="{ errors }">
                        <b-form-group label-for="email">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <b-icon icon="envelope"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input id="email" v-model.trim="form.email" :state="errors[0] ? false : null" placeholder="Email"></b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </v-validation-provider>

                    <v-validation-provider name="Password" rules="required" v-slot="{ errors }">
                        <b-form-group label-for="password">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <b-icon icon="lock"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input id="password" v-model.trim="form.password" :state="errors[0] ? false : null" placeholder="Password" type="password"></b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </v-validation-provider>

                    <b-form-group class="text-center">
                        <b-button class="mb-2" variant="primary" type="submit" :disabled="loading" block>
                            <b-spinner small v-if="loading" style="margin-bottom:2px;"></b-spinner> {{ loading ? 'Logging-in...' : 'Submit' }}
                        </b-button>
                        <small>Forgot Password? <b-link class="text-decoration-none" to="/forgotpassword">Click Here!</b-link></small>
                    </b-form-group>
                </b-form>
            </v-validation-observer>
        </b-card>
    </div>
</div>
</template>

<script>
import Api from '../services/ApiService'
import { API_ROUTES } from '../constants'
export default {
    data: () => ({
        form: {
            email: null,
            password: null
        },
        loading: false,
        alert: null
    }),
    methods: {
        async onSubmit() {
            this.loading = true
            try {
                let response = await Api.post(API_ROUTES.POST_LOGIN(), JSON.stringify(this.form))
                Api.defaults.headers.common['x-auth-token'] = response.data.token;
                localStorage.setItem('auth', JSON.stringify(response.data));
                
                if(this.$route.query.nextUrl){
                    let nextUrl = decodeURIComponent(`${this.$route.query.nextUrl}`)
                    this.$router.push(nextUrl)
                }else{
                    this.$router.push({ name: "Home" })
                }
            } catch (error) {
                this.alert = { type: 'danger', message: error.data.message  }
            }finally{
                this.loading = false
            }
        }
    }
}
</script>