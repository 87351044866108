<template>
  <div class="container-fluid vh-80 w-100 my-3">
    <template v-if="loading && !userData.token && users.length === 0">
      <div class="row">
        <div class="col-sm-12">
          <div class="card shadow-sm d-flex justify-content-center align-items-center" :style="{ height: '5rem'}">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading && !userData.token && users.length === 0">
      <div class="row">
        <div class="col-sm-12">
          <div class="card shadow-sm d-flex justify-content-center align-items-center" :style="{ height: '5rem'}">
            <h5>Your account don't have Amazon 2FA code and shared accounts! Please contact the administrator.</h5>
          </div>
        </div>
      </div>
    </template>

    <template v-if="userData.token || users.length > 0">
      <div class="row mb-2">
        <div class="col-sm-12">
          <span>Codes will update in {{ counter === 0 ? '~' : counter }}...</span>
          <small class="float-right">
            <strong>Own Account Status: <span :class="userData.token ? 'text-success' : 'text-danger'">{{ userData.token ? 'Found' : 'Not Found' }}</span>  | Shared Accounts: {{ users.length }}</strong>
          </small>
        </div>
      </div>
    </template>

    <template v-if="userData.token">
      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="card shadow-sm">
          <div class="d-flex align-items-center justify-content-between px-3 py-5" :style="{ height: '5rem'}">
            <div>
              <h1 :style="{ marginBottom: '0px' }">{{ userData.token }}</h1>
              <span class="badge bg-primary text-white">Own Account</span> <small class="text-muted">{{ userData.email }}</small>
            </div>
            <div>
              <b-button variant="success" class="px-3" @click="copyCode(userData.token)">Copy Code</b-button><br />
            </div>
          </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="users">
      <div class="row">
        <div class="col-sm-12 mb-2" v-for="(item, index) in users" :key="index">
          <div class="card shadow-sm">
            <div class="d-flex align-items-center justify-content-between px-3 py-5" :style="{ height: '5rem'}">
              <div>
                <h1 v-if="item.token" :style="{ marginBottom: '0px' }">{{ item.token }}</h1>
                <h2 v-else class="text-muted" :style="{ marginBottom: '0px' }">No Token Found</h2>
                <span class="badge bg-secondary text-white">Shared Account</span> <small class="text-muted">{{ item.shared_user }}</small>
              </div>
              <div>
                <b-button v-if="item.token" variant="success" class="px-3" @click="copyCode(item.token)">Copy Code</b-button>
                <b-button v-else disabled variant="secondary" class="px-3" style="cursor: not-allowed;">Copy Code</b-button><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api, { authStorage } from '../services/ApiService'
import { API_ROUTES } from '../constants'

export default {
  data: () => ({
    userData: {
      id: null, email: '', token: '', timeRemaining: null
    },
    loading: false,
    users: [],
    counter: 0,
    timer: null
  }),
  async created(){
    await this.generate2faCode()
    this.timer = setInterval(this.setTimer, 1000)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  methods: {
    setTimer(){
      if(!this.userData.token && this.users.length === 0 && this.counter === 0){
        clearInterval(this.timer)
        return
      }

      if(this.counter > 0){
        this.counter--
      }else{
        this.generate2faCode()
      }
    },
    async generate2faCode(){
      this.loading = true
      try {
        const auth = await authStorage()
        let response = await Api.get(API_ROUTES.GET_GENERATE_CODE(auth.id))
        let usersResponse = await Api.get(API_ROUTES.GET_SHARED_USERS_CODE())
        this.users = usersResponse.data

        if(response.data && response.data.token){
          this.userData = response.data
          this.counter = response.data.timeRemaining
        }
        if(!response.data.token && usersResponse.data.length > 0){
          this.counter = usersResponse.data[0].timeRemaining
        }
        this.loading = false
      } catch (error) {
        this.userData = {
          id: null, email: '', token: '', timeRemaining: null
        }
        this.loading = false
      }
    },
    copyCode(data) {
      const els = document.createElement('textarea');
      els.value = data;
      document.body.appendChild(els);
      els.select();
      document.execCommand('copy');
      document.body.removeChild(els)
      this.$bvToast.toast(`Code(${data}) Copied!`, {
        title: `Success`,
        variant: 'success',
        solid: true
      })
    }
  },
}
</script>