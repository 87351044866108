<template>
  <b-container fluid class="my-3">
    <div class="d-flex justify-content-between mb-2">
      <h5><strong>User Logs</strong></h5>
      <div></div>
    </div>

    <b-card>
      <b-card-text>
        <b-row class="justify-content-center">
          <b-col sm md="4" lg="4">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="search"
                  placeholder="Search Email"
                  @input="loadUserLogs"
                  type="search"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <div>
          <b-table
          small
          id="userlogs-table"
          class="border-bottom"
          show-empty
          :empty-text="loading ? 'Loading...' : 'There are no user logs to show'"
          :busy="loading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          stacked="sm"
          hover
          >
            <template #cell(user_group_id)="data">
              {{ 
                data.item.user_group_id === ROLE.ADMIN ? 'Admin' : 
                data.item.user_group_id === ROLE.USER ? 'User' :
                data.item.user_group_id === ROLE.GUEST ? 'Guest' : ''
              }}
            </template>

            <template #cell(remarks)="data">
              <span v-if="!data.item.remarks" class="badge bg-secondary text-white px-2">null</span>
              <small :title="data.item.remarks">{{ data.item.remarks }}</small>
            </template>
          </b-table>
          <b-pagination
            v-if="items.length > 0"
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            aria-controls="userlogs-table"
            last-number
            first-number
            align="center"
            hide-goto-end-buttons
            @change="onPaginate"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </b-container>
</template>


<script>
import Api from '../services/ApiService'
import { formatDate } from '../services/DateService'
import { ROLE, API_ROUTES } from '../constants'
export default {
  data: () => ({
    ROLE,
    search: '',
    loading: false,
    items: [],
    fields: [
      {
        key: 'email',
        label: 'Email',
        class: 'align-middle'
      },
      {
        key: 'user_group_id',
        label: 'Role',
        class: 'text-center align-middle'
      },
      {
        key: 'ip_address',
        label: 'IP Address',
        class: 'text-center align-middle'
      },
      {
        key: 'loggedin_at',
        label: 'Created At',
        class: 'text-center align-middle'
      },
      {
        key: 'log_type',
        label: 'Log Type',
        class: 'text-center align-middle'
      },
      {
        key: 'remarks',
        label: 'Remarks',
        class: 'align-middle'
      },
    ],
    pagination: {
      currentPage: 1,
      perPage: 20,
      totalRows: 1
    },
  }),
  created() {
    this.loadUserLogs()
  },
  methods: {
    async loadUserLogs(){
      this.loading = true
      let { data } = await Api.get(API_ROUTES.GET_USER_LOGS(this.pagination.perPage, this.pagination.currentPage, this.search))
      let items = data.items.map(item => {
        return {
          ...item,
          loggedin_at: item.loggedin_at ? formatDate(item.loggedin_at) : null
        }
      })
      this.items = items
      this.pagination = {
        ...this.pagination,
        totalRows: data.totalItems
      }
      this.loading = false
    },
    onPaginate(page){
      this.pagination = { ...this.pagination, currentPage: page }
      this.loadUserLogs()
    }
  }
}
</script>