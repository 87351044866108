import axios from 'axios';

const Api = axios.create({
	headers: {
		'content-type': 'application/json'
	},
	timeout: 60000
});

Api.interceptors.request.use(
	config => {
		const auth = JSON.parse(localStorage.getItem('auth'));
		if (auth) {
			config.headers.common['x-auth-token'] = auth.token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

Api.interceptors.response.use(
  response => {
		if (response.status === 200 || response.status === 201) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
  error => {
    if (error.response.status === 401) {
      Api.defaults.headers.common['x-auth-token'] = '';
      localStorage.removeItem('auth')
      window.location.reload();
    }
    return Promise.reject(error.response);
	}	
);

export const authStorage = () => localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;

export const clearAuth = () => {
  Api.defaults.headers.common['x-auth-token'] = '';
  localStorage.removeItem('auth')
};

export default Api;