<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark" fixe="top">
      <b-navbar-brand href="#">2FA Generator</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item to="/">Home</b-nav-item>
            <b-nav-item-dropdown v-if="role === ROLE.ADMIN" text="Admin" right>
              <b-dropdown-item to="/admin/users" exact exact-active-class="active">Manage Users</b-dropdown-item>
              <b-dropdown-item to="/admin/logs" exact exact-active-class="active">User Logs</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-form>
              <b-button type="button" variant="outline-info" @click="logout">Logout</b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { clearAuth } from '../services/ApiService'
import { ROLE } from '../constants'

export default {
  data: () => ({
    ROLE
  }),
  computed: {
    role(){
      return localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).role : null
    },
  },
  methods: {
    logout(){
      clearAuth();
      this.$router.push('/login')
    }
  },
}
</script>