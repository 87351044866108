import Vue from "vue";
import VueRouter from "vue-router";
import { ROLE } from '../constants'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../pages/Container.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../pages/Home.vue"),
        meta: {
          title: "Home",
          requiresAuth: true,
          permissions: [ROLE.ADMIN, ROLE.USER]
        }
      },
      {
        path: "/admin/users",
        name: "Users",
        component: () => import("../pages/Users.vue"),
        meta: {
          title: "Users",
          requiresAuth: true,
          permissions: [ROLE.ADMIN]
        }
      },
      {
        path: "/admin/logs",
        name: "UserLogs",
        component: () => import("../pages/UserLogs.vue"),
        meta: {
          title: "User Logs",
          requiresAuth: true,
          permissions: [ROLE.ADMIN]
        }
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/Login.vue"),
    meta: {
      title: "Login",
    }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../pages/ForgotPassword.vue"),
    meta: {
      title: "Forgot Password",
    }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../pages/PageNotFound.vue"),
    meta: {
      title: "Page Not Found",
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {	
	let auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!auth){
			next({ path: '/login', query: { nextUrl: to.fullPath } })
		} else {
			if(to.meta.permissions.includes(auth.role)){
				next();
			}else{
				next({ path: '/'});
			}
		}
	} else {
		if(auth){
			next({ path: from.fullPath });
		}
    next();
	}
});

router.afterEach((to) => {
	document.title = `${to.meta.title} | 2FA Generator` || '2FA Generator';
});

export default router;
